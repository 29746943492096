.header {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 40px 0px;

  @include media-query(md) {
    padding: 80px 0px;
  }
}

.header-logo {
  display: inline-block;
  vertical-align: middle;
}

.header-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.nav-social {
  ul {
    display: flex;
    padding: 0px;
    margin: 0px;
  }

  li {
    list-style: none;
    margin-left: 16px;

    &:first-child {
      margin-left: 0px;
    }
  }

  a {
    &:hover {
      color: $neonblue;
    }

    &.is-active {
      font-weight: $extrabold;
      color: $neonblue;
    }
  }
}