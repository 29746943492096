.footer {
  padding: 40px 0px;
  background-color: $darkness;

  @include media-query(md) {
    padding: 80px 0px;
  }
}

.footer-block {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  text-align: center;

  > * {
    margin-top: 40px;

    &:first-child {
      margin-top: 0px;
    }
  }

  @include media-query(md) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    
    > * {
      margin-top: 0px;
    }
  }
}