/*
 * Config
 */

 $base-font-family: Arial, sans-serif;
 $base-font-size: 16px;
 $base-font-weight: normal;
 $base-font-color: #000;
 
 $container-gutter: 36px;
 
 $grid-columns: 12;
 $grid-breakpoints: (
   xs: 480px,
   sm: 640px,
   md: 800px,
   lg: 960px,
   xl: 1120px,
   xxl: 1280px
 );
 