.hero {
  text-align: center;

  @include media-query(md) {
    text-align: left;
  }
}

.hero-visual {
  @include media-query(md) {
    position: relative;
    z-index: 0;

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0%;
      left: 0%;
      display: block;
      width: 210%;
      height: 0%;
      margin-top: -100%;
      margin-left: -10%;
      padding-bottom: 210%;
      background-image: url(../img/corner.svg);
      background-size: cover;
    }
  }
}

.hero-block {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0px -36px;

  > * {
    margin-top: 40px;
    padding: 0px 36px;

    &:first-child {
      margin-top: 0px;
    }
  }

  @include media-query(md) {
    flex-direction: row-reverse;
    align-items: center;

    > * {
      margin-top: 0px;
      width: 50%;
    }
  }
}