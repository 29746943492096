.form-row {
  margin-top: 36px;

  &:first-child {
    margin-top: 0px;
  }
}

.label {
  font-size: rem(18px);
  font-weight: $extrabold;
  color: $darkness;
  margin-left: 12px;

  span {
    font-size: rem(12px);
    color: $steel;
    vertical-align: middle;

    &:before {
      content: '[';
    }

    &:after {
      content: ']';
    }
  }

  & + .input-textarea {
    margin-top: 12px;
  }
}

.input {
  display: block;
  width: 100%;
  
  input {
    display: block;
    appearance: none;
    width: 100%;
    min-height: 48px;
    border: 0px;
    padding: 8px 12px;
    background-color: transparent;
    font-size: rem(16px);
  }

  textarea {
    display: block;
    appearance: none;
    width: 100%;
    min-height: 192px;
    border: 0px;
    padding: 12px;
    background-color: transparent;
    font-size: rem(16px);
  }
}

.input-text {
  input {
    border-bottom: 2px solid lighten($steel, 20%);
    transition: border-color .1s ease-out;

    &:focus {
      outline: 0px;
      border-bottom-color: $neonblue;
      transition: border-color .1s ease-in;
    }

    &.error {
      border-bottom-color: #fa7268;
    }
  }
}

.input-textarea {
  textarea {
    border: 2px solid lighten($steel, 20%);
    transition: border-color .1s ease-out;

    &:focus {
      outline: 0px;
      border-color: $neonblue;
      transition: border-color .1s ease-in;
    }

    &.error {
      border-color: #fa7268;
    }
  }
}