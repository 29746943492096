/*
 * Molecule
 */
/*
 * Config
 */
/*
 * Rem
 */
/*
 * Media query
 */
/*
 * Reset
 */
*, *:before, *:after {
  box-sizing: border-box; }

html {
  width: 100%;
  height: 100%;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: #000;
  vertical-align: baseline; }

body {
  width: 100%;
  min-height: 100%;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 1rem; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

[hidden] {
  display: none; }

a {
  text-decoration: none;
  color: inherit; }

img,
svg {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  height: auto; }

/*
 * Container
 */
.container {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0 36px; }
  @media (min-width: 480px) {
    .container {
      max-width: 480px; } }
  @media (min-width: 640px) {
    .container {
      max-width: 640px; } }
  @media (min-width: 800px) {
    .container {
      max-width: 800px; } }
  @media (min-width: 960px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1120px) {
    .container {
      max-width: 1120px; } }
  @media (min-width: 1280px) {
    .container {
      max-width: 1280px; } }

/*
 * Grid
 */
.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch; }

.grid-item-1 {
  width: 8.33333%; }

.grid-item-2 {
  width: 16.66667%; }

.grid-item-3 {
  width: 25%; }

.grid-item-4 {
  width: 33.33333%; }

.grid-item-5 {
  width: 41.66667%; }

.grid-item-6 {
  width: 50%; }

.grid-item-7 {
  width: 58.33333%; }

.grid-item-8 {
  width: 66.66667%; }

.grid-item-9 {
  width: 75%; }

.grid-item-10 {
  width: 83.33333%; }

.grid-item-11 {
  width: 91.66667%; }

.grid-item-12 {
  width: 100%; }

@media (min-width: 480px) {
  .grid-item-1-xs {
    width: 8.33333%; }
  .grid-item-2-xs {
    width: 16.66667%; }
  .grid-item-3-xs {
    width: 25%; }
  .grid-item-4-xs {
    width: 33.33333%; }
  .grid-item-5-xs {
    width: 41.66667%; }
  .grid-item-6-xs {
    width: 50%; }
  .grid-item-7-xs {
    width: 58.33333%; }
  .grid-item-8-xs {
    width: 66.66667%; }
  .grid-item-9-xs {
    width: 75%; }
  .grid-item-10-xs {
    width: 83.33333%; }
  .grid-item-11-xs {
    width: 91.66667%; }
  .grid-item-12-xs {
    width: 100%; } }

@media (min-width: 640px) {
  .grid-item-1-sm {
    width: 8.33333%; }
  .grid-item-2-sm {
    width: 16.66667%; }
  .grid-item-3-sm {
    width: 25%; }
  .grid-item-4-sm {
    width: 33.33333%; }
  .grid-item-5-sm {
    width: 41.66667%; }
  .grid-item-6-sm {
    width: 50%; }
  .grid-item-7-sm {
    width: 58.33333%; }
  .grid-item-8-sm {
    width: 66.66667%; }
  .grid-item-9-sm {
    width: 75%; }
  .grid-item-10-sm {
    width: 83.33333%; }
  .grid-item-11-sm {
    width: 91.66667%; }
  .grid-item-12-sm {
    width: 100%; } }

@media (min-width: 800px) {
  .grid-item-1-md {
    width: 8.33333%; }
  .grid-item-2-md {
    width: 16.66667%; }
  .grid-item-3-md {
    width: 25%; }
  .grid-item-4-md {
    width: 33.33333%; }
  .grid-item-5-md {
    width: 41.66667%; }
  .grid-item-6-md {
    width: 50%; }
  .grid-item-7-md {
    width: 58.33333%; }
  .grid-item-8-md {
    width: 66.66667%; }
  .grid-item-9-md {
    width: 75%; }
  .grid-item-10-md {
    width: 83.33333%; }
  .grid-item-11-md {
    width: 91.66667%; }
  .grid-item-12-md {
    width: 100%; } }

@media (min-width: 960px) {
  .grid-item-1-lg {
    width: 8.33333%; }
  .grid-item-2-lg {
    width: 16.66667%; }
  .grid-item-3-lg {
    width: 25%; }
  .grid-item-4-lg {
    width: 33.33333%; }
  .grid-item-5-lg {
    width: 41.66667%; }
  .grid-item-6-lg {
    width: 50%; }
  .grid-item-7-lg {
    width: 58.33333%; }
  .grid-item-8-lg {
    width: 66.66667%; }
  .grid-item-9-lg {
    width: 75%; }
  .grid-item-10-lg {
    width: 83.33333%; }
  .grid-item-11-lg {
    width: 91.66667%; }
  .grid-item-12-lg {
    width: 100%; } }

@media (min-width: 1120px) {
  .grid-item-1-xl {
    width: 8.33333%; }
  .grid-item-2-xl {
    width: 16.66667%; }
  .grid-item-3-xl {
    width: 25%; }
  .grid-item-4-xl {
    width: 33.33333%; }
  .grid-item-5-xl {
    width: 41.66667%; }
  .grid-item-6-xl {
    width: 50%; }
  .grid-item-7-xl {
    width: 58.33333%; }
  .grid-item-8-xl {
    width: 66.66667%; }
  .grid-item-9-xl {
    width: 75%; }
  .grid-item-10-xl {
    width: 83.33333%; }
  .grid-item-11-xl {
    width: 91.66667%; }
  .grid-item-12-xl {
    width: 100%; } }

@media (min-width: 1280px) {
  .grid-item-1-xxl {
    width: 8.33333%; }
  .grid-item-2-xxl {
    width: 16.66667%; }
  .grid-item-3-xxl {
    width: 25%; }
  .grid-item-4-xxl {
    width: 33.33333%; }
  .grid-item-5-xxl {
    width: 41.66667%; }
  .grid-item-6-xxl {
    width: 50%; }
  .grid-item-7-xxl {
    width: 58.33333%; }
  .grid-item-8-xxl {
    width: 66.66667%; }
  .grid-item-9-xxl {
    width: 75%; }
  .grid-item-10-xxl {
    width: 83.33333%; }
  .grid-item-11-xxl {
    width: 91.66667%; }
  .grid-item-12-xxl {
    width: 100%; } }

/*
 * Theme
 */
/*
 * Config
 */
/*
 * Web
 */
html {
  width: 100%; }

body {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
  color: #a0a4b0; }

h1, h2, h3,
h4, h5, h6 {
  margin: 0px 0px 0.75rem;
  line-height: 1.25; }

p, ul, ol {
  margin: 0px 0px 1.5rem; }

h1 {
  font-size: 3.375rem;
  font-weight: 800;
  color: #252525; }

h2 {
  font-size: 2.625rem;
  font-weight: 800;
  color: #252525; }

h3 {
  font-size: 1.75rem;
  font-weight: 800;
  color: #252525; }

strong {
  color: #2b63f3; }

.text-container > *:last-child {
  margin-bottom: 0px; }

.top:before {
  content: '';
  display: block;
  width: 100%;
  height: 0%;
  padding-bottom: 10%;
  background-image: url(../img/top.svg);
  background-size: cover;
  margin-bottom: 40px; }
  @media (min-width: 800px) {
    .top:before {
      margin-bottom: 80px; } }

.bottom:after {
  content: '';
  display: block;
  width: 100%;
  height: 0%;
  padding-bottom: 10%;
  background-image: url(../img/bottom.svg);
  background-size: cover;
  margin-top: 40px; }
  @media (min-width: 800px) {
    .bottom:after {
      margin-top: 80px; } }

.button {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 64px;
  padding: 16px 48px;
  border-radius: 32px;
  border: 0px;
  background-color: #a0a4b0;
  font-size: 1.125rem;
  font-weight: 800;
  line-height: 1;
  color: #fff;
  cursor: pointer;
  transition: background-color .1s ease-in; }
  .button:focus {
    outline: 0px; }
  .button:hover {
    background-color: #848999;
    transition: background-color .1s ease-out; }

.button-primary {
  background-color: #2b63f3; }
  .button-primary:hover {
    background-color: #0d47de; }

.popup {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99;
  top: -100%;
  left: -100%;
  opacity: 0;
  transition: opacity .2s ease-out, top 0s .2s, left 0s .2s; }
  .popup[data-popup-state="is-active"] {
    top: 0px;
    left: 0px;
    opacity: 1;
    transition: opacity .2s ease-in; }

.popup-background {
  width: 100%;
  height: 100%;
  background-color: rgba(43, 99, 243, 0.9);
  display: flex;
  padding: 24px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow: auto; }

.popup-modal {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 640px;
  background-color: #fff;
  padding: 48px 36px;
  margin: auto;
  border-radius: 8px; }

.popup-close {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  font-size: 2.25rem;
  color: #a0a4b0;
  transition: color .1s ease-out; }
  .popup-close:hover {
    color: #2b63f3;
    transition: color .1s ease-in; }

.form-row {
  margin-top: 36px; }
  .form-row:first-child {
    margin-top: 0px; }

.label {
  font-size: 1.125rem;
  font-weight: 800;
  color: #252525;
  margin-left: 12px; }
  .label span {
    font-size: 0.75rem;
    color: #a0a4b0;
    vertical-align: middle; }
    .label span:before {
      content: '['; }
    .label span:after {
      content: ']'; }
  .label + .input-textarea {
    margin-top: 12px; }

.input {
  display: block;
  width: 100%; }
  .input input {
    display: block;
    appearance: none;
    width: 100%;
    min-height: 48px;
    border: 0px;
    padding: 8px 12px;
    background-color: transparent;
    font-size: 1rem; }
  .input textarea {
    display: block;
    appearance: none;
    width: 100%;
    min-height: 192px;
    border: 0px;
    padding: 12px;
    background-color: transparent;
    font-size: 1rem; }

.input-text input {
  border-bottom: 2px solid #d8d9de;
  transition: border-color .1s ease-out; }
  .input-text input:focus {
    outline: 0px;
    border-bottom-color: #2b63f3;
    transition: border-color .1s ease-in; }
  .input-text input.error {
    border-bottom-color: #fa7268; }

.input-textarea textarea {
  border: 2px solid #d8d9de;
  transition: border-color .1s ease-out; }
  .input-textarea textarea:focus {
    outline: 0px;
    border-color: #2b63f3;
    transition: border-color .1s ease-in; }
  .input-textarea textarea.error {
    border-color: #fa7268; }

.header {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 40px 0px; }
  @media (min-width: 800px) {
    .header {
      padding: 80px 0px; } }

.header-logo {
  display: inline-block;
  vertical-align: middle; }

.header-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center; }

.nav-social ul {
  display: flex;
  padding: 0px;
  margin: 0px; }

.nav-social li {
  list-style: none;
  margin-left: 16px; }
  .nav-social li:first-child {
    margin-left: 0px; }

.nav-social a:hover {
  color: #2b63f3; }

.nav-social a.is-active {
  font-weight: 800;
  color: #2b63f3; }

.hero {
  text-align: center; }
  @media (min-width: 800px) {
    .hero {
      text-align: left; } }

@media (min-width: 800px) {
  .hero-visual {
    position: relative;
    z-index: 0; }
    .hero-visual:after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0%;
      left: 0%;
      display: block;
      width: 210%;
      height: 0%;
      margin-top: -100%;
      margin-left: -10%;
      padding-bottom: 210%;
      background-image: url(../img/corner.svg);
      background-size: cover; } }

.hero-block {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0px -36px; }
  .hero-block > * {
    margin-top: 40px;
    padding: 0px 36px; }
    .hero-block > *:first-child {
      margin-top: 0px; }
  @media (min-width: 800px) {
    .hero-block {
      flex-direction: row-reverse;
      align-items: center; }
      .hero-block > * {
        margin-top: 0px;
        width: 50%; } }

.usp-overview {
  width: 100%;
  padding: 80px 0px; }
  @media (min-width: 800px) {
    .usp-overview {
      padding: 160px 0px; } }

.usp-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  margin: 0px -36px; }

.usp {
  padding: 0px 36px;
  margin-top: 40px;
  text-align: center; }
  .usp:first-child {
    margin-top: 0px; }
  @media (min-width: 640px) {
    .usp {
      width: 50%; }
      .usp:nth-child(-n+2) {
        margin-top: 0px; } }
  @media (min-width: 960px) {
    .usp {
      width: 33.33%; }
      .usp:nth-child(-n+3) {
        margin-top: 0px; } }

.usp-icon {
  display: inline-flex;
  width: 64px;
  height: 64px;
  margin: 0px 0px 36px;
  fill: #2b63f3; }

.feature-overview {
  background-color: #f8f6fe;
  text-align: center; }
  @media (min-width: 800px) {
    .feature-overview {
      text-align: left; } }

.feature-call-to-action {
  margin-top: 40px; }
  @media (min-width: 800px) {
    .feature-call-to-action {
      text-align: center; } }

.feature {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  margin: 80px -36px 0px; }
  .feature > * {
    padding: 0px 36px; }
  .feature:first-child {
    margin-top: 0px; }
  @media (min-width: 800px) {
    .feature {
      flex-direction: row-reverse;
      align-items: center; }
      .feature:nth-child(even) {
        flex-direction: row; } }

@media (min-width: 800px) {
  .feature-content {
    width: 60%; } }

.feature-visual {
  margin-bottom: 24px; }
  @media (min-width: 800px) {
    .feature-visual {
      width: 40%; } }

.feature-count {
  font-size: 4rem;
  font-weight: 800;
  color: #2b63f3; }

.api-overview {
  padding: 80px 0px; }
  @media (min-width: 800px) {
    .api-overview {
      padding: 160px 0px; } }

.api-intro {
  text-align: center; }

.api-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0px -36px; }
  .api-list > * {
    padding: 0px 36px;
    margin-top: 40px; }

.technology {
  padding: 0px 0px 80px;
  background-color: #f8f6fe;
  text-align: center; }
  @media (min-width: 800px) {
    .technology {
      padding: 0px 0px 160px; } }

.technology-block {
  display: flex;
  flex-direction: column; }
  .technology-block > * {
    margin-top: 80px; }
    .technology-block > *:first-child {
      margin-top: 0px; }
  @media (min-width: 800px) {
    .technology-block {
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0px -36px; }
      .technology-block > * {
        width: 50%;
        padding: 0px 36px;
        margin-top: 0px; } }

.technology-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0px -18px; }
  .technology-list > * {
    padding: 0px 18px;
    margin-top: 40px; }

.footer {
  padding: 40px 0px;
  background-color: #252525; }
  @media (min-width: 800px) {
    .footer {
      padding: 80px 0px; } }

.footer-block {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  text-align: center; }
  .footer-block > * {
    margin-top: 40px; }
    .footer-block > *:first-child {
      margin-top: 0px; }
  @media (min-width: 800px) {
    .footer-block {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center; }
      .footer-block > * {
        margin-top: 0px; } }

/*
 * Print
 */
