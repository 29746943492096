.usp-overview {
  width: 100%;
  padding: 80px 0px;

  @include media-query(md) {
    padding: 160px 0px;
  }
}

.usp-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  width: auto;
  margin: 0px -36px;
}

.usp {
  padding: 0px 36px;
  margin-top: 40px;
  text-align: center;
  
  &:first-child {
    margin-top: 0px;
  }

  @include media-query(sm) {
    width: 50%;

    &:nth-child(-n+2) {
      margin-top: 0px;
    }
  }

  @include media-query(lg) {
    width: 33.33%;
    
    &:nth-child(-n+3) {
      margin-top: 0px;
    }
  }
}

.usp-icon {
  display: inline-flex;
  width: 64px;
  height: 64px;
  margin: 0px 0px 36px;
  fill: $neonblue;
}