/*
 * Config
 */

$darkness: #252525;
$steel: #a0a4b0;
$aqua: #f8f6fe;
$neonblue: #2b63f3;

$medium: 500;
$bold: 700;
$extrabold: 800;