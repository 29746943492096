.popup {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99;
  top: -100%;
  left: -100%;
  opacity: 0;
  transition: 
    opacity .2s ease-out,
    top 0s .2s,
    left 0s .2s;

  &[data-popup-state="is-active"] {
    top: 0px;
    left: 0px;
    opacity: 1;;
    transition: opacity .2s ease-in;
  }
}

.popup-background {
  width: 100%;
  height: 100%;
  background-color: rgba($neonblue, .9);
  display: flex;
  padding: 24px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.popup-modal {
  position: relative;
  width: 100%;
  height: auto;
  max-width: map-get($grid-breakpoints, sm);
  background-color: #fff;
  padding: 48px 36px;
  margin: auto;
  border-radius: 8px;
}

.popup-close {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  font-size: rem(36px);
  color: $steel;
  transition: color .1s ease-out;

  &:hover {
    color: $neonblue;
    transition: color .1s ease-in;
  }
}