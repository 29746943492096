.technology {
  padding: 0px 0px 80px;
  background-color: $aqua;
  text-align: center;

  @include media-query(md) {
    padding: 0px 0px 160px;
  }
}

.technology-block {
  display: flex;
  flex-direction: column;
  
  > * {
    margin-top: 80px;

    &:first-child {
      margin-top: 0px;
    }
  }

  @include media-query(md) {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0px -36px;

    > * {
      width: 50%;
      padding: 0px 36px;
      margin-top: 0px;
    }
  }
}

.technology-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0px -18px;

  > * {
    padding: 0px 18px;
    margin-top: 40px;
  }
}