/*
 * Container
 */

.container {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0 $container-gutter;
  
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-query($breakpoint) {
      max-width: map-get($grid-breakpoints, $breakpoint);
    }
  }
}