.button {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 64px;
  padding: 16px 48px;
  border-radius: 32px;
  border: 0px;
  background-color: $steel;
  font-size: rem(18px);
  font-weight: $extrabold;
  line-height: 1;
  color: #fff;
  cursor: pointer;
  transition: background-color .1s ease-in;

  &:focus {
    outline: 0px;
  }

  &:hover {
    background-color: darken($steel, 10%);
    transition: background-color .1s ease-out;
  }
}

.button-primary {
  background-color: $neonblue;

  &:hover {
    background-color: darken($neonblue, 10%);
  }
}