.feature-overview {
  background-color: $aqua;
  text-align: center;

  @include media-query(md) {
    text-align: left;
  }
}

.feature-call-to-action {
  margin-top: 40px;

  @include media-query(md) {
    text-align: center;
  }
}

.feature {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  margin: 80px -36px 0px;

  > * {
    padding: 0px 36px;
  }

  &:first-child {
    margin-top: 0px;
  }

  @include media-query(md) {
    flex-direction: row-reverse;
    align-items: center;

    &:nth-child(even) {
      flex-direction: row;
    }
  }
}

.feature-content {
  @include media-query(md) {
    width: 60%;
  }
}

.feature-visual {
  margin-bottom: 24px;

  @include media-query(md) {
    width: 40%;
  }
}

.feature-count {
  font-size: rem(64px);
  font-weight: $extrabold;
  color: $neonblue;
}