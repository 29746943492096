/*
 * Reset
 */

*, *:before, *:after {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: $base-font-weight;
  color: $base-font-color;
  vertical-align: baseline;
}

body {
  width: 100%;
  min-height: 100%;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 1rem;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

[hidden] {
  display: none;
}

a {
  text-decoration: none;
  color: inherit;
}

img,
svg {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  height: auto;
}