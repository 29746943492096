/*
 * Web
 */

html {
  width: 100%;
}

body {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: rem(18px);
  font-weight: $medium;
  line-height: 1.5;
  color: $steel;
}

h1, h2, h3,
h4, h5, h6 {
  margin: 0px 0px rem(12px);
  line-height: 1.25;
}

p, ul, ol {
  margin: 0px 0px rem(24px);
}

h1 {
  font-size: rem(54px);
  font-weight: $extrabold;
  color: $darkness;
}

h2 {
  font-size: rem(42px);
  font-weight: $extrabold;
  color: $darkness;
}

h3 {
  font-size: rem(28px);
  font-weight: $extrabold;
  color: $darkness;
}

strong {
  color: $neonblue;
}

.text-container {
  > *:last-child {
    margin-bottom: 0px;
  }
}

.top {
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 0%;
    padding-bottom: 10%;
    background-image: url(../img/top.svg);
    background-size: cover;
    margin-bottom: 40px;

    @include media-query(md) {
      margin-bottom: 80px;
    }
  }
}

.bottom {
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 0%;
    padding-bottom: 10%;
    background-image: url(../img/bottom.svg);
    background-size: cover;
    margin-top: 40px;

    @include media-query(md) {
      margin-top: 80px;
    }
  }
}

@import 'components/buttons';
@import 'components/popup';
@import 'components/forms';

@import 'partials/header';
@import 'partials/hero';
@import 'partials/unique-selling-points';
@import 'partials/features';
@import 'partials/api';
@import 'partials/technology';
@import 'partials/footer';